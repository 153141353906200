
import { defineComponent, onMounted, reactive, toRefs, computed } from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import { message } from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
import ImageView from "@/components/ImageView.vue";
import DriverSet from "@/components/DriverSet.vue";
import PageUtil from "@/utils/page_util";
import { IDriverItem, IEditDriver } from "@/models/logistics_member";

interface IState {
  list: any;
  total: number;
  nickname:any,
  loading: boolean;
  searches: any;
  replyVisible:boolean,
  replymessage:any,
  replyId:any,
  visible: boolean;
  editItem?: IEditDriver;
  detail:any
}

// 表格
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width:100,
    align: "center"
  },
  
  {
    title: "反馈内容",
    dataIndex: "content",
    key: "content",
    width:900,

  },
    {
    title: "反馈人",
    dataIndex: "nickname",
    key: "nickname",
    width:150,
      align: "center"
  },
  {
    title: "反馈人电话",
    dataIndex: "mobile",
    key: "mobile",
    width:150,
    align: "center"
  },
  {
    title: "回复",
    dataIndex: "reply",
    key: "reply",

  },
  
  {
    title: "操作",
    key: "action",
    align:'center',
    width:180
  },
];

export default defineComponent({
  name: "DriverList",
  components: {
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    ImageView,
    DriverSet,
  },
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible:false,
      replyVisible:false,
      replymessage:'',
      replyId:"",
      nickname:'',
      detail:'',
      searches: {
        page: 1,
        limit	: 10,
        state: "", // 类型 0没回复1回复
        keywords:""
      },
    });

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize;
      state.searches.page_no = pages.current;
      getList();
    };

    //获取列表
    const getList = async () => {
      let params = { ...state.searches };
      console.log(params)
      const res = await ApiLogistics.feedbackList(params);
      if (res && res.error_code === 0) {
           state.list = res.data;
      }
    };


    // 设置modal显示隐藏
    const setVisible = (item: IDriverItem | undefined, flg: boolean) => {
      console.log(item);
      state.visible = flg;
      if (item && flg) {
        state.editItem = {
          id: item.id,
          name: item.name,
          gender: item.gender,
          phone: item.phone,
          vehicle_type: item.vehicle_type,
          lng: item.lng,
          lat: item.lat,
          idle: Boolean(item.idle),
          disable: Boolean(item.disable),
          head_img: item.head_img,
        };
      } else {
        setTimeout(() => (state.editItem = undefined), 300);
      }
    };


    const getDetail = async (record:any) => {
      state.visible = true
      state.nickname = ''
        let params = {id:record.id}
         const res = await ApiLogistics.feedbackDetail(params);
        if (res && res.error_code === 0) {
            state.detail = res.data
          if(state.detail.user !== null){
            state.nickname = state.detail.user.nickname
          }else{
            state.nickname = '未完善'
          }
        }
    }

    const reply = async (record:any) => {
      state.replyVisible = true
      state.replymessage = ''
      // console.log(state,"bbbbbbbbbb")
      state.replyId = record.id
      let params = {id:record.id}
      const res = await ApiLogistics.feedbackDetail(params);
      if (res && res.error_code === 0) {
        state.detail = res.data
        if(state.detail.user !== null){
          state.nickname = state.detail.user.nickname
        }else{
          state.nickname = '未完善'
        }
      }
    }

    const replySubmit = async () => {
      console.log(state.replymessage)
      let params = {pid:state.replyId,reply:state.replymessage}
      const res = await ApiLogistics.FeedbackReply(params);
      if (res && res.error_code === 0) {
        state.replyVisible = false
        message.success(res.msg)
           getList()
      }
    }



    

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = "";
      }
      state.searches.page_no = 1;
      state.searches.page_size = PageUtil.pageSize;
      getList();
    };

    const searchData = () =>{
      state.searches.page_no = 1;
      state.searches.page_size = PageUtil.pageSize;
      getList()
    }




    onMounted(() => {
      getList();
    });

    return {
      ...toRefs(state),
      columns,
      pagination,
      resetSearch,
      replySubmit,
      handleTableChange,
      getDetail,
      setVisible,
      reply,
      searchData
    };
  },
});
